import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const ConceptoEstimadoChart = ({ total, estimaciones }) => {
  const [currentData, setCurrentData] = useState([{ data: [] }]);

  useEffect(() => {
    if (estimaciones && total) {
      let acumulado = 0;
      const estimados = estimaciones?.map(({ updatedAt, estimado }, index) => {
        const current = (parseFloat(estimado) / parseFloat(total)) * 100 + acumulado;
        acumulado = current;
        return {
          x: updatedAt,
          y: current,
        };
      });

      acumulado = 0;
      const autorizados = estimaciones?.map(({ updatedAt, autorizado }, index) => {
        const current = (parseFloat(autorizado) / parseFloat(total)) * 100 + acumulado;
        acumulado = current;
        return {
          x: updatedAt,
          y: current,
        };
      });

      setCurrentData([
        {
          name: "Estimado",
          data: estimados,
        },
        {
          name: "Autorizado",
          data: autorizados,
        },
      ]);
    }
  }, [estimaciones, total]);

  return (
    <Chart
      type="area"
      options={{
        stroke: {
          curve: "smooth",
        },
        colors: ["#007991", "#89D405"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        yaxis: {
          min: 0,
          labels: {
            formatter: (val) => `${parseFloat(val).toFixed(2)}%`,
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            show: true,
            hideOverlappingLabels: true,
            showDuplicates: false,
            offset: -1,
          },
        },
      }}
      series={currentData}
    />
  );
};

export default ConceptoEstimadoChart;
