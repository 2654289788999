import React, { useContext, useEffect } from "react";
import { UserDevicesContext } from "../context/UserDevicesContext";
import DeviceForm from "../components/devices/DeviceForm";
import { CapacitorContext } from "../context/CapacitorContext";
import { ModalContext } from "../context/ModalContext";

const usePushNotifications = () => {
  const { modalComponent } = useContext(ModalContext);
  const { platform, registered, device_token, registerNotifications } =
    useContext(CapacitorContext);
  const {
    user_device,
    user_devices,
    setUserDevice,
    getUserDevices,
    createUserDevice,
    setPropertyUserDevice,
  } = useContext(UserDevicesContext);

  useEffect(() => {
    getUserDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (platform !== "web")
    registerNotifications(platform);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform]);

  useEffect(() => {
    if (Array.isArray(user_devices) && device_token !== null && user_device === null) {
      handleUserDevice(user_devices, device_token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device_token, user_devices]);

  useEffect(() => {
    if (user_device !== null) {
      if (!user_device.device_token)
        setPropertyUserDevice("device_token", device_token);
      handleEditDevice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_device]);

  const findUserDeviceIndexByToken = (device_token) => {
    if (!Array.isArray(user_devices)) return -1;
    const device_index = user_devices.findIndex(
      (device) => device.token === device_token
    );
    return device_index;
  };

  const handleEditDevice = () => {
    modalComponent("Register Device", <DeviceForm />);
  };

  const handleUserDevice = (user_devices, device_token) => {
    const device_index = findUserDeviceIndexByToken(device_token);
    if (device_index !== -1) setUserDevice(user_devices[device_index]);
    else createUserDevice();
  };

  return { registered, device_token };
};

export default usePushNotifications;
