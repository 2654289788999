
import {
  SET_ESTADOESTIMACION,
  CREATE_ESTADOESTIMACION,
  ESTADOESTIMACIONS_RECEIVED,
  SET_PROPERTY_ESTADOESTIMACION,
} from "../types/estadosEstimacion";

const schema = {

}

const EstimacionEstadosReducer = (state, { type, payload }) => {
  switch (type) {
    case ESTADOESTIMACIONS_RECEIVED:
      return { ...state, estadosEstimacion: payload };
    case SET_ESTADOESTIMACION:
      return { ...state, estimacionEstado: payload };
    case CREATE_ESTADOESTIMACION:
      return { ...state, estimacionEstado: schema };
    case SET_PROPERTY_ESTADOESTIMACION: {
      const { key, value } = payload;
      const estimacionEstado = { ...state.estimacionEstado };
      estimacionEstado[key] = value;
      return { ...state, estimacionEstado };
    }
    default:
      return { ...state};
  }
};

export default EstimacionEstadosReducer;
