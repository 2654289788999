import React from "react";

const EstimacionEstadoAlertaCard = ({ alerta, handleEdit, handleDelete }) => {
  return (
    <div
      key={alerta.idEstimacionEstadoAlerta}
      className="card p-3 py-md-4 shadow mb-3"
    >
      <div className="row">
        <div className="col-12 col-md-9 text-capitalize">
          <span className="bg-light d-inline-block mb-2 p-2 border br-10 text-dark px-3 me-2">
            <i className="fa fa-user me-2" />
            {alerta.Permiso?.nombre}
          </span>
          <span className="bg-light d-inline-block mb-2 p-2 border br-10 text-dark px-3">
            <i className="fa fa-shapes me-2" />
            {alerta.PlantillaAlerta?.name}
          </span>
          <div className="container-fluid px-0 mt-md-4 my-3">
            <span className="border p-2 br-10">
              {alerta.EstadoAnterior?.nombre}
            </span>
            <i className="fa fa-arrow-right mx-3" />
            <span className="border p-2 br-10">
              {alerta.EstadoActual?.nombre}
            </span>
            <span className="ms-3"></span>
            <span
              className={`badge badge-pill text-uppercase ${
                alerta.tipo === "verification"
                  ? "bg-warning text-dark"
                  : "bg-primary"
              }`}
            >
              {alerta.tipo}
            </span>
          </div>
        </div>
        <div className="col-12 col-md-3 text-end">
          <button
            onClick={() => handleEdit(alerta)}
            className="btn btn-outline-primary"
          >
            <i className="fa fa-edit" />
          </button>
          <button
            onClick={() => handleDelete(alerta)}
            className="btn btn-outline-danger ms-2"
          >
            <i className="fa fa-trash" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default EstimacionEstadoAlertaCard;
