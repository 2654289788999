import React, { createContext, useContext, useReducer } from "react";
import EntradasReducer from "../reducers/EntradasReducer";
import { ModalContext } from "./ModalContext";
import {
  PREPEND_ENTRADAS,
  SET_MAX_ENTRADAS,
  CLEAR_BITACORA,
  APPEND_ENTRADA,
  APPEND_ADJUNTO,
  CANCEL_ADJUNTO,
  CLEAR_ADJUNTOS,
  RESET_ENTRADAS,
  SET_PARAMS,
  SET_RESUMEN,
} from "../types/entradas";
import { HIDE_SPINNER, SHOW_SPINNER } from "../actions/types";
import BitacoraService from "../services/BitacoraService";
import AdjuntosService from "../services/AdjuntosService";

const initialState = {
  entradas: null,
  adjuntos: null,
  resumen: null,
  params: {},
  limit: 25,
  offset: 0,
  max: 0,
};
export const EntradasContext = createContext(initialState);

export const EntradasProvider = ({ children }) => {
  const [state, dispatch] = useReducer(EntradasReducer, initialState);

  const { alert } = useContext(ModalContext);

  const setParams = (params) => {
    dispatch({ type: SET_PARAMS, payload: params });
  }

  const clearBitacora = () => {
    dispatch({ type: CLEAR_BITACORA });
  };

  const appendEntrada = (entrada) => {
    dispatch({ type: SET_MAX_ENTRADAS, payload: 1 });
    dispatch({ type: APPEND_ENTRADA, payload: entrada });
  };

  const getLength = (idProyecto) => {
    BitacoraService.getChatLength(idProyecto)
      .then((res) => {
        const { length } = res.data;
        dispatch({ type: SET_MAX_ENTRADAS, payload: length });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getEntradas = (idProyecto, offset, limit, params) => {
    console.log(params);
    dispatch({ type: SHOW_SPINNER });
    BitacoraService.getEntradas(idProyecto, limit, offset, params)
      .then((res) => {
        const { entradas } = res.data;
        dispatch({ type: PREPEND_ENTRADAS, payload: entradas });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const getEntradasResumen = (idProyecto, offset, limit, params) => {
    dispatch({ type: SHOW_SPINNER });
    dispatch({ type: SET_RESUMEN, payload: null });
    BitacoraService.getEntradasResumen(idProyecto, limit, offset, params)
      .then((res) => {
        const { resumen } = res.data;
        dispatch({ type: SET_RESUMEN, payload: resumen });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const resetEntradas = () => {
    dispatch({ type: RESET_ENTRADAS });
  };

  const previewAdjunto = (nombre, src) => {
    dispatch({ type: APPEND_ADJUNTO, payload: { nombre, src } });
  };

  const cancelAdjunto = (index) => {
    dispatch({ type: CANCEL_ADJUNTO, payload: index });
  };

  const clearAdjuntos = () => {
    dispatch({ type: CLEAR_ADJUNTOS });
  };

  const postEntrada = (idProyecto, entrada, callback) => {
    const { files } = entrada;
    if (Array.isArray(files) && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        let formData = AdjuntosService.getFormData(files[i]);
        AdjuntosService.postAdjuntoBitacora(idProyecto, formData).then(
          (res) => {
            const { idAdjunto } = res.data;
            BitacoraService.postEntrada(idProyecto, {
              ...entrada,
              message: "",
              idAdjunto,
            });
          }
        );
      }
    }

    return new Promise((resolve, reject) => {
      BitacoraService.postEntrada(idProyecto, entrada)
        .then((res) => {
          const { entrada } = res.data;
          dispatch({ type: APPEND_ENTRADA, payload: entrada });
          if (typeof callback === "function") {
            callback();
          }

          resolve(entrada);
        })
        .catch((error) => {
          alert(error);
          reject(error);
        });
    });
  };

  return (
    <EntradasContext.Provider
      value={{
        ...state,
        getLength,
        setParams,
        postEntrada,
        getEntradas,
        clearBitacora,
        appendEntrada,
        clearAdjuntos,
        cancelAdjunto,
        previewAdjunto,
        resetEntradas,
        getEntradasResumen,
      }}
    >
      {children}
    </EntradasContext.Provider>
  );
};
