
import React, { createContext, useReducer, useContext } from 'react';
import EstimacionEstadoAlertasService from '../services/EstimacionEstadoAlertasService';
import EstimacionEstadoAlertasReducer from '../reducers/EstimacionEstadoAlertasReducer';
import {
  ESTIMACIONESTADOALERTAS_RECEIVED,
  SET_ESTIMACIONESTADOALERTA,
  CREATE_ESTIMACIONESTADOALERTA,
  SET_PROPERTY_ESTIMACIONESTADOALERTA,
} from "../types/estimacionEstadoAlertas";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  estimacionEstadoAlertas: null,
  estimacionEstadoAlerta: null,
};

export const EstimacionEstadoAlertasContext = createContext(initialState);

export const EstimacionEstadoAlertasProvider = ({ children }) => {
  const [state, dispatch] = useReducer(EstimacionEstadoAlertasReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getEstimacionEstadoAlertas = (filters) => {
    EstimacionEstadoAlertasService.getEstimacionEstadoAlertas(filters)
      .then((response) => {
        const { estimacionestadoalertas } = response.data;
        dispatch({ type: ESTIMACIONESTADOALERTAS_RECEIVED, payload: estimacionestadoalertas });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleEstimacionEstadoAlerta = (idEstimacionEstadoAlerta) => {
    EstimacionEstadoAlertasService.getSingleEstimacionEstadoAlerta(idEstimacionEstadoAlerta)
      .then((response) => {
        const { estimacionestadoalerta } = response.data;
        dispatch({ type: SET_ESTIMACIONESTADOALERTA, payload: estimacionestadoalerta });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setEstimacionEstadoAlerta = (EstimacionEstadoAlerta) => {
    dispatch({ type: SET_ESTIMACIONESTADOALERTA, payload: EstimacionEstadoAlerta });
  };

  const createEstimacionEstadoAlerta = () => {
    dispatch({ type: CREATE_ESTIMACIONESTADOALERTA });
  };

  const setPropertyEstimacionEstadoAlerta = (key, value) => {
    dispatch({ type: SET_PROPERTY_ESTIMACIONESTADOALERTA, payload: { key, value } });
  };

  const saveEstimacionEstadoAlerta = (EstimacionEstadoAlerta, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = EstimacionEstadoAlertasService.putEstimacionEstadoAlerta;
      if(isNaN(parseInt(EstimacionEstadoAlerta.idEstimacionEstadoAlerta))) {
        service = EstimacionEstadoAlertasService.postEstimacionEstadoAlerta;
      }
      service(EstimacionEstadoAlerta).then(() => {
        success("EstimacionEstadoAlerta saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteEstimacionEstadoAlerta = (idEstimacionEstadoAlerta, callback) => {
     dispatch({ type: SHOW_SPINNER });
    EstimacionEstadoAlertasService.deleteEstimacionEstadoAlerta(idEstimacionEstadoAlerta).then(() => {
      success("EstimacionEstadoAlerta deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <EstimacionEstadoAlertasContext.Provider
    value={{
      ...state,
      setEstimacionEstadoAlerta,
      getEstimacionEstadoAlertas,
      saveEstimacionEstadoAlerta,
      deleteEstimacionEstadoAlerta,
      createEstimacionEstadoAlerta,
      getSingleEstimacionEstadoAlerta,
      setPropertyEstimacionEstadoAlerta,

    }}
  >
    {children}
  </EstimacionEstadoAlertasContext.Provider>
);
};
