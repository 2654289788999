import React, { useContext, useEffect } from "react";
import moment from "moment";
import CheckBox from "./CheckBox";
import { sortableHandle } from "react-sortable-hoc";
import { ModalContext } from "../../context/ModalContext";
import { PartidasContext } from "../../context/PartidasContext";
import AvancePickerMobile from "../cronograma/AvancePickerMobile";
import DynamicSearchSelect from "../global/DynamicMultipleSearchSelect";

const DragHandle = sortableHandle(() => (
  <span className="pr-1">
    <i className="fas fa-grip-lines"></i>
  </span>
));

const PartidaForm = ({ partida }) => {
  const { confirm } = useContext(ModalContext);
  const { partidas, setPropertyPartida, reordenarPartidas, deletePartida } =
    useContext(PartidasContext);

    useEffect(() => {
      if(!partida.dependencias) {
        const dependencias = partida.predecesoras.map(({ idPartida }) => ({ value: idPartida}));
        handleDependenciasPartida(dependencias);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  function handleDelete() {
    confirm("¿Eliminar partida " + partida.nombre + "?", () =>
      deletePartida(partida.idPartida)
    );
  }

  function handleDependenciasPartida(dependencias) {
    setPropertyPartida(
      partida.idPartida,
      "dependencias",
      dependencias.map(({ value }) => value)
    );
  }

  return (
    <tr onDragOver={reordenarPartidas} draggable>
      <td className="mw-50-px">
        <DragHandle />
      </td>
      <td className="text-end border-right picker">
        <AvancePickerMobile
          intervalo={1}
          progreso={partida.ponderacion}
          setCantidad={(ponderacion) =>
            setPropertyPartida(partida.idPartida, "ponderacion", ponderacion)
          }
        />
      </td>
      <td className="border-right" style={{ minWidth: 330 }}>
        <input
          type="text"
          value={partida.nombre}
          className="form-control"
          onChange={(e) =>
            setPropertyPartida(partida.idPartida, "nombre", e.target.value)
          }
        />
      </td>
      <td className="border-right text-center">
        <CheckBox
          checked={partida.punto_critico}
          modifier={setPropertyPartida}
          args={[partida.idPartida, "punto_critico"]}
        />
      </td>
      <td className="border-right ps-3">
        <input
          type="date"
          className="form-control"
          value={moment(partida.fecha_inicio).format("YYYY-MM-DD")}
          onChange={(e) =>
            setPropertyPartida(
              partida.idPartida,
              "fecha_inicio",
              e.target.value
            )
          }
        />
      </td>
      <td className="border-right ps-3">
        <input
          type="date"
          className="form-control"
          value={moment(partida.fecha_fin).format("YYYY-MM-DD")}
          onChange={(e) =>
            setPropertyPartida(partida.idPartida, "fecha_fin", e.target.value)
          }
        />
      </td>
      <td className="border-right ps-3" style={{ minWidth: 250 }}>
        <DynamicSearchSelect
          labelKey="nombre"
          valueKey="idPartida"
          modifier={handleDependenciasPartida}
          value={partida.dependencias}
          items={partidas?.filter(({ idPartida }) => !isNaN(parseInt(idPartida)))}
        />
      </td>
      <td className="text-center">
        <button className="btn btn-outline-danger" onClick={handleDelete}>
          <i className="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  );
};

export default PartidaForm;
