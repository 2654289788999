import React, { useContext, useEffect } from "react";
import PartidaForm from "./PartidaForm";
import PartidaTabla from "./PartidaTabla";
import EncabezadoTabla from "./EncabezadoTabla";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { ModalContext } from "../../context/ModalContext";
import { PartidasContext } from "../../context/PartidasContext";
import { isCoordinador } from "../../utils/permisos";
import { getValue } from "../../utils";
import { AppConfigContext } from "../../context/AppConfigContext";
import { AuthContext } from "../../context/AuthContext";

const PartidaFila = SortableElement((props) => (
  <PartidaForm
    partida={props.partida}
    partidas={props.partidas}
    idProyecto={props.idProyecto}
    key={`partida_${props.partida.idPartida}`}
  />
));

const PartidasContainer = SortableContainer((props) => (
  <tbody>{props.renderPartidas()}</tbody>
));

const ListaCotejo = ({
  level,
  proyecto,
  partidas,
  destajos,
  editMode,
  viewLevel,
  editModeOn,
  editModeOff,
  getProyecto,
  subdestajos,
  showDetails,
}) => {
  const { idProyecto } = proyecto;

  const {
    setPartida,
    createNuevaPartida,
    reordenarPartidas,
    postPartidasProyecto,
  } = useContext(PartidasContext);
  const { user } = useContext(AuthContext);
  const { confirm } = useContext(ModalContext);
  const appconfig = useContext(AppConfigContext);

  useEffect(() => {
    viewLevel("partidas");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onEditMode() {
    return (isCoordinador(proyecto) || isCoordinador(user)) && editMode;
  }

  function handleCancel() {
    editModeOff();
    getProyecto();
  }

  function getPorcentajeCompletado() {
    if (getValue(appconfig, "progress") === "money") {
      return proyecto.progreso;
    }
    if (proyecto.real) return proyecto.real * 100;
    return 0;
  }

  function handleCreate() {
    createNuevaPartida(partidas.length, idProyecto);
  }

  function handleDetails(partida) {
    setPartida(partida);
    showDetails();
  }

  function renderPartidas() {
    if (Array.isArray(partidas)) {
      let partidasRender = partidas.filter(
        (partida) => String(partida.idPartida)[0] !== "d"
      );
      if (editMode === "partidas") {
        return partidasRender.map((partida, index) => (
          <PartidaFila
            index={index}
            partida={partida}
            proyecto={proyecto}
            partidas={partidas}
            key={`partida_${partida.idPartida}`}
          />
        ));
      } else {
        return partidasRender.map((partida) => (
          <PartidaTabla
            level={level}
            partida={partida}
            proyecto={proyecto}
            editMode={editMode}
            destajos={destajos}
            viewLevel={viewLevel}
            subdestajos={subdestajos}
            getProyecto={getProyecto}
            permiso={proyecto.permiso}
            showDetails={handleDetails}
            key={`partida_${partida.idPartida}`}
          />
        ));
      }
    }
  }

  function renderButton() {
    let components = [];
    if (editMode === "partidas") {
      components = [
        <button
          className="btn btn-primary me-2"
          key="save-partidas-btn"
          onClick={handlePostPartidas}
        >
          Guardar
        </button>,
        <button
          className="btn btn-outline-primary me-2"
          onClick={handleCreate}
          key="add-partidas-btn"
        >
          <i className="fa fa-plus"></i>
          Agregar Partida
        </button>,
        <button
          key={"cancelPartida"}
          className="btn btn-outline-danger"
          onClick={() =>
            confirm(
              "¿Está seguro que desea cancelar? Los cambios a las Partidas no se guardarán.",
              handleCancel
            )
          }
        >
          Cancelar
        </button>,
      ];
    } else if (!editMode) {
      components = [
        <button
          className="btn btn-outline-primary"
          onClick={() => editModeOn("partidas")}
          key="edit-partidas"
        >
          <i className="fa fa-edit"></i> Editar Partidas
        </button>,
      ];
    }
    return components;
  }

  function renderContent() {
    if (proyecto && proyecto !== null) {
      const editing = onEditMode();
      return (
        <div className="table-responsive">
          <table className="table mb-0">
            <thead>
              <EncabezadoTabla editMode={editing} />
            </thead>
            <PartidasContainer
              useDragHandle
              pressDelay={100}
              onSortEnd={reordenarPartidas}
              renderPartidas={renderPartidas}
            />
          </table>
          <div className="row small mx-0 my-2 align-items-center">
            <div className="col-4 col-md-3 col-lg-2 col-xl-1 text-center">
              <span style={{ fontWeight: 800 }}>
                {getPorcentajeCompletado()}
              </span>
              /100{"%"}
            </div>
            <div className="col-8 col-md-9 col-lg-10 col-xl-11">
              {renderButton()}
            </div>
          </div>
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  }

  function handlePostPartidas() {
    postPartidasProyecto(idProyecto, partidas, getProyecto);
  }

  return (
    <div className="container-fluid cronograma-desktop px-0">
      <div className="card br-10 mb-3 p-2 shadow-sm">{renderContent()}</div>
    </div>
  );
};

export default ListaCotejo;
