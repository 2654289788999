import React from "react";

const PlantillaAlertaCard = ({ plantillaAlerta, handleEdit, handleDelete }) => {
  return (
    <div className="col-12 col-md-6 col-xl-4">
      <div className="card mb-3 shadow border">
        <div className="card-header bg-white py-3">
          <div className="row">
            <div className="col-8">
              <h3 className="h5 mb-0 card-title">{plantillaAlerta.name}</h3>
            </div>
            <div className="col-4 text-end">
              <button
                className="btn btn-outline-primary"
                onClick={() => handleEdit(plantillaAlerta)}
              >
                <i className="fa fa-edit" />
              </button>
              <button
                className="btn btn-outline-danger ms-2"
                onClick={() => handleDelete(plantillaAlerta)}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <h4 className="h6 bold">{plantillaAlerta.title}</h4>
          <p className="mb-2">{plantillaAlerta.subtitle}</p>
          <p className="small">{plantillaAlerta.body}</p>
        </div>
      </div>
    </div>
  );
};

export default PlantillaAlertaCard;
