import React, { useContext, useState } from "react";
import Obra from "./views/Obra";
import Obras from "./views/Obras";
import Alertas from "./views/Alertas";
import Usuarios from "./views/Usuarios";
import Clientes from "./views/Clientes";
import Menu from "./components/menu/Menu";
import { CapacitorContext } from "./context/CapacitorContext";
import BottomNavbar from "./components/global/BottomNavbar";
import MenuMobile from "./components/menu/MenuMobile";
import SingleCliente from "./views/SingleCliente";
import { Routes, Route } from "react-router-dom";
import Permisos from "./views/Permisos";
import Navbar from "./views/Navbar";
import Ajustes from "./views/Ajustes";
import { AppConfigContext } from "./context/AppConfigContext";
import { getValue } from "./utils";
import Almacen from "./views/Almacen";
import SingleProduct from "./views/SingleProduct";
import PurchaseOrder from "./views/PurchaseOrder";
import Proveedores from "./views/Proveedores";
import SingleProveedor from "./views/SingleProveedor";
import MobileSubHeader from "./components/movil/MobileSubHeader";
import usePushNotifications from "./hooks/usePushNotifications";
import { MenuContext } from "./context/MenuContext";
import { hasNotch } from "./utils/ionic";
import useProyecto from "./hooks/useProyecto";
import { AuthContext } from "./context/AuthContext";
import User from "./components/menu/User";
import WhatsApp from "./views/WhatsApp";
import PlantillasAlerta from "./views/PlantillasAlerta";
import EstimacionEstados from "./views/EstimacionEstados";
import EstimacionEstadoAlertas from "./views/EstimacionEstadoAlertas";

const Home = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const { permiso } = useProyecto();
  const { user } = useContext(AuthContext);
  const appconfig = useContext(AppConfigContext);
  const { platform, device } = useContext(CapacitorContext);
  const { buttons, selected } = useContext(MenuContext);

  usePushNotifications();

  const renderBottomNavbar = () => {
    if (platform !== "web" && permiso !== "contratista" && user.permiso !== "contratista") {
      return <BottomNavbar />;
    }
  };

  const renderAjustes = () => {
    if (getValue(appconfig, "show_settings", "boolean")) {
      return <Route path="/ajustes" element={<Ajustes />} />;
    }
  };

  const renderMobileSubHeader = () => {
    
    if(platform !== "web" && selected !== null) {
      return <MobileSubHeader />
    }
  }

  const isBitacora = window.location.pathname.includes('bitacora');

  const getContainerView = () => {
    if((permiso === "contratista" || user.permiso === "contratista") && platform !== "web") {
      return "calc(100% + 50px)";
    }
    if(platform === 'web') {
      return '100%';
    } else if(buttons === null && selected !== null) {
      return 'calc(100% - 35.2px)';
    } else if(selected !== null) {
      return 'calc(100% - 50px)';
    }
    return 'calc(100% + 35px);'
  }

  const getContentPaddingTop = () => {
    if(platform !== 'web') {
      if(hasNotch(device)) {
        return {
          paddingTop: 109
        };
      } 
    }
  }

  return (
    <div 
      className={`
        container-fluid vw-100 px-0 vh-100 
        ${platform !== 'web' ? 'tablet-device' : ''}
      `}
    >
      <Menu setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
      <Navbar menuOpen={menuOpen} />
      <div 
        id="content" 
        className={`bg-light h-100 ${platform !== "web" ? "px-2": ""}`}
        style={{
          paddingBottom: `${platform !== 'web' && '75px'}`,
          ...getContentPaddingTop()
        }}
      >
        <MenuMobile />
        {renderMobileSubHeader()}
        <div 
          className={`
            ${platform === 'web' ? 'px-2' : ''} 
            ${!isBitacora ? 'py-3' : ''}
            ${isBitacora ? 'pb-2' : ''}`
          }
          style={{
            height: getContainerView(),
            overflowY: "scroll",
          }}
        >
          <Routes>
            {renderAjustes()}
            <Route path="/" element={<Obras />} />
            <Route path="/perfil" element={<User />} />
            <Route path="/usuarios" element={<Usuarios />} />
            <Route path="/clientes" element={<Clientes />} />
            <Route path="/permisos" element={<Permisos />} />
            <Route path="/obra/:idProyecto/*" element={<Obra />} />
            <Route path="/clientes/:idCliente" element={<SingleCliente />} />
            {/* Bot */}
            <Route path="/whatsapp" element={<WhatsApp />} />
            {/* Alertas */}
            <Route path="/alertas" element={<Alertas />} />
            <Route path="/plantillas" element={<PlantillasAlerta />} />
            {/* Estado Estimacion */}
            <Route path="/estados" element={<EstimacionEstados />} />
            <Route path="/estados/:idEstadoEstimacion/alertas" element={<EstimacionEstadoAlertas />} />
            {/* Proveedores */}
            <Route path="/proveedores" element={<Proveedores />} />
            <Route
              path="/proveedores/:idProveedor"
              element={<SingleProveedor />}
            />
            {/* Almacen */}
            <Route path="/almacen" element={<Almacen />} />
            <Route
              path="/almacen/producto/:idProducto"
              element={<SingleProduct />}
            />
            {/* Ordenes de Compra */}
            <Route path="/orden/:idOrden" element={<PurchaseOrder />} />
          </Routes>
        </div>
        {renderBottomNavbar()}
      </div>
    </div>
  );
};

export default Home;
