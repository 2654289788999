import React from "react";

const EncabezadoTabla = ({ editMode }) => {
  return (
    <tr className="bg-light border p-2 bold">
      {editMode && (
        <td style={{ width: 50 }}>
          <i className="fas fa-sort"></i>
        </td>
      )}
      <td className={editMode ? "picker" : "ponderacion"}>%</td>
      <td className="ps-3 rubro">Rubro</td>
      {!editMode && <td className="text-center">Avance</td>}
      <td className="ps-3">Crítico</td>
      <td className="ps-3" style={{ minWidth: 150 }}>
        Fecha Inicio
      </td>
      <td className="ps-3" style={{ minWidth: 150 }}>
        Fecha Fin
      </td>
      <td style={{ minWidth: 250, maxWidth: 250, width: 250 }}>Dependencias</td>
      <td>Acciones</td>
    </tr>
  );
};

export default EncabezadoTabla;
