import React, { useContext } from "react";
import { getValue, formatMonto } from "../../utils";
import { AuthContext } from "../../context/AuthContext";
import { getConceptosKeys, getSumConcepto } from "../../utils/conceptos";
import { ConceptosUbicacionesContext } from "../../context/ConceptosUbicacionesContext";

const ConceptoMedicionForm = ({
  concepto,
  idProyecto,
  idContrato,
  handleCancel,
  handleCallback,
  idEstimacionVersion,
}) => {
  const { user } = useContext(AuthContext);
  const { saveConceptoUbicacion, setPropertyConceptosUbicacion } = useContext(
    ConceptosUbicacionesContext
  );

  const handleSave = () => {
    if (!isNaN(parseInt(concepto.idConceptoMedicion))) {
      if (concepto.idUsuario !== user.idUsuario) {
        delete concepto.idConceptoMedicion;
        delete concepto.idUsuario;
      }
    }
    concepto.idEstimacionVersion = idEstimacionVersion;
    saveConceptoUbicacion(idProyecto, idContrato, concepto, handleCallback);
  };

  const renderCampos = () => {
    const keys = getConceptosKeys(concepto);
    return keys.map((key) => (
      <td className="border" key={key}>
        <input
          type="text"
          className="form-control"
          value={getValue(concepto, key)}
          onChange={(e) =>
            setPropertyConceptosUbicacion(
              concepto.idConceptoUbicacion,
              key,
              e.target.value
            )
          }
        />
      </td>
    ));
  };

  const renderTotal = () => {
    const total = getSumConcepto(concepto);
    return formatMonto(total);
  };

  return (
    <>
    <tr>
      {["eje", "tramo", "tipo", "nivel"].map((key) => (
        <td className="border" key={key}>
          <input
            type="text"
            className="form-control"
            value={getValue(concepto, key)}
            onChange={(e) =>
              setPropertyConceptosUbicacion(
                concepto.idConceptoUbicacion,
                key,
                e.target.value
              )
            }
          />
        </td>
      ))}
      {renderCampos()}
      <td className="border">{renderTotal()}</td>
      <td className="border">
        <button
          onClick={handleSave}
          className="btn btn-sm btn-outline-dark me-2"
        >
          <i className="fa fa-save" />
        </button>
        <button
          onClick={() => handleCancel(concepto.idConceptoUbicacion)}
          className="btn btn-sm btn-outline-danger"
        >
          <i className="fa fa-times" />
        </button>
      </td>
    </tr>
    </>
  );
};

export default ConceptoMedicionForm;
