import React, { useContext, useEffect } from "react";
import ChecklistCard from "../components/checklists/ChecklistCard";
import ChecklistForm from "../components/checklists/ChecklistForm";
import { CapacitorContext } from "../context/CapacitorContext";
import { ChecklistContext } from "../context/ChecklistContext";
import { ModalContext } from "../context/ModalContext";
import { MenuContext } from "../context/MenuContext";

const Checklists = ({ idProyecto }) => {
  const {
    checklists,
    editChecklist,
    deleteChecklist,
    createChecklist,
    getChecklistsProyecto,
  } = useContext(ChecklistContext);
  const { setTitle, setButtons, setSelected } = useContext(MenuContext);
  const { platform } = useContext(CapacitorContext);
  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    if (platform !== "web") {
      setTitle("Checklists");
      setButtons(
        <button
          onClick={handleCreateChecklist}
          className="btn btn-primary"
        >
          <i className="fa fa-plus" />
        </button>
      );
    }
    setSelected({ title: "checklists" });
    getChecklistsProyecto(idProyecto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateChecklist = () => {
    createChecklist();
    modalComponent(
      "Crear Checklist",
      <ChecklistForm idProyecto={idProyecto}  />
    );
  };

  const handleEditChecklist = (checklist) => {
    editChecklist(checklist);
    modalComponent(
      "Editar Checklist",
      <ChecklistForm idProyecto={idProyecto} />
    );
  };

  const handleDelete = (checklist) => {
    modalComponent(
      "Precaución",
      <div>
        <p>
          ¿Estás seguro que deseas eliminar el checklist {checklist.nombre}?
          Esta acción NO puede deshacerse.
        </p>
        <button
          className="btn btn-danger"
          onClick={() => deleteChecklist(idProyecto, checklist.idChecklist)}
        >
          Eliminar
        </button>
      </div>
    );
  };

  const renderChecklists = () => {
    if (Array.isArray(checklists)) {
      if (checklists.length === 0) {
        return (
          <p className="mb-0 py-2">
            No hay checklists registrados para este proyecto.
          </p>
        );
      }
      return checklists.map((checklist) => (
        <div className="col-12 col-md-6 col-xl-4" key={checklist.idChecklist}>
          <ChecklistCard
            checklist={checklist}
            handleDelete={handleDelete}
            handleEdit={handleEditChecklist}
          />
        </div>
      ));
    }
  };

  const renderHeader = () => {
    if (platform === "web") {
      return (
        <div className="row align-items-center mb-3">
          <div className="col-12 col-md-6"></div>
          <div className="col-12 col-md-6 text-end px-3">
            <button
              className="btn btn-primary"
              onClick={handleCreateChecklist}
            >
              <i className="fa fa-plus" />
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="container-fluid py-3">
      {renderHeader()}
      <div className="row">{renderChecklists()}</div>
    </div>
  );
};

export default Checklists;
