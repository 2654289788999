import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { EstimacionEstadoAlertasContext } from "../context/EstimacionEstadoAlertasContext";
import EstimacionEstadoAlertaForm from "../components/alertas/EstimacionEstadoAlertaForm";
import { ModalContext } from "../context/ModalContext";
import { MenuContext } from "../context/MenuContext";
import EstimacionEstadoAlertaCard from "../components/alertas/EstimacionEstadoAlertaCard";
import ConfirmDelete from "../components/global/ConfirmDelete";
import { EstimacionEstadosContext } from "../context/EstimacionEstadosContext";

const EstimacionEstadoAlertas = () => {
  const { idEstadoEstimacion } = useParams();

  const { setSelected, selectTabs } = useContext(MenuContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { estimacionEstado, getSingleEstimacionEstado } = useContext(EstimacionEstadosContext)
  const {
    estimacionEstadoAlertas,
    setEstimacionEstadoAlerta,
    getEstimacionEstadoAlertas,
    createEstimacionEstadoAlerta,
    deleteEstimacionEstadoAlerta,
  } = useContext(EstimacionEstadoAlertasContext);

  useEffect(() => {
    selectTabs();
    setSelected({ title: "Alertas" });
    getSingleEstimacionEstado(idEstadoEstimacion)
    getEstimacionEstadoAlertas({ idEstadoEstimacion });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAlertas = () => getEstimacionEstadoAlertas({ idEstadoEstimacion });

  const handleCreate = () => {
    createEstimacionEstadoAlerta();
    modalComponent(
      "Agregar Alerta",
      <EstimacionEstadoAlertaForm handleCancel={clearModal} handleCallback={fetchAlertas} />
    );
  };

  const handleEdit = (estimacionEstadoAlerta) => {
    setEstimacionEstadoAlerta(estimacionEstadoAlerta);
    modalComponent(
      "Agregar Alerta",
      <EstimacionEstadoAlertaForm handleCancel={clearModal} handleCallback={fetchAlertas} />
    );
  };

  const handleDelete = (alerta) => {
    modalComponent(
      "Eliminar Alerta",
      <ConfirmDelete
        handleCancel={clearModal}
        message={`¿Estás seguro que deseas eliminar esta alerta?`}
        handleDelete={() =>
          deleteEstimacionEstadoAlerta(alerta.idEstimacionEstadoAlerta, fetchAlertas)
        }
      />
    );
  };

  const renderAlertas = () => {
    if (Array.isArray(estimacionEstadoAlertas)) {
      if (estimacionEstadoAlertas.length === 0) {
        return <p>No has registrado alertas para este estado de estimación</p>;
      }
      return estimacionEstadoAlertas.map((alerta) => (
        <EstimacionEstadoAlertaCard
          alerta={alerta}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          key={alerta.idEstimacionEstadoAlerta}
        />
      ));
    }
  };

  return (
    <div className="container-fluid">
      <div className="row mb-3 align-items-center">
        <div className="col-10">
          <h1 className="h3 mb-0">Alertas para {estimacionEstado?.nombre}</h1>
        </div>
        <div className="col-2 text-end">
          <button onClick={handleCreate} disabled={estimacionEstado === null} className="btn btn-primary">
            <i className="fa fa-plus" />
          </button>
        </div>
      </div>
      {renderAlertas()}
    </div>
  );
};

export default EstimacionEstadoAlertas;
