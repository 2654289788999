import React from "react";
import usePreciario from "../../hooks/usePreciario";
import { formatMonto, getValue } from "../../utils";
import { getTipo } from "../../utils/conceptos";
import { Link } from "react-router-dom";

const ConceptosEstimacion = ({ conceptos }) => {
  const { acumulados, estimacion, handleComments, renderPrecioControl } =
    usePreciario();

  const renderAcumulado = (concepto) => {
    const anterior = acumulados?.find(({ clave }) => clave === concepto.clave);
    if (anterior && anterior !== null) {
      if (anterior.acumulado_estimado > 0) {
        return (
          <Link
            to={`./concepto/${concepto.idConcepto}`}
            className="text-primary text-underline"
          >
            {anterior.acumulado_estimado}
          </Link>
        );
      }
      return anterior.acumulado_estimado;
    }
  };

  const renderConceptos = () => {
    if (Array.isArray(conceptos)) {
      return conceptos.map((concepto) => (
        <tr
          key={concepto.idConcepto}
          className={`border-bottom concepto-${getTipo(concepto)}`}
        >
          <td style={{ minWidth: 150 }} className="text-underline">
            <button
              data-bs-toggle="tooltip" 
              data-bs-placement="top"
              title={concepto.nombre}
              data-bs-title={concepto.nombre}
              data-bs-custom-class="custom-tooltip"
              className="btn px-0 py-0 text-underline fw-normal"
            >
              {concepto.clave}
            </button>
          </td>
          <td>{concepto.unidad}</td>
          <td>{concepto.cantidad}</td>
          <td>{renderAcumulado(concepto)}</td>
          <td style={{ minWidth: 150 }}>
            ${formatMonto(concepto.precio_solicitado)}
          </td>
          {getValue(estimacion, "tipo") === "extra" && (
            <td style={{ minWidth: 200 }}>{renderPrecioControl(concepto)}</td>
          )}
          <td>{formatMonto(concepto.estimado)}</td>
          <td>{formatMonto(concepto.supervisado)}</td>
          <td>{formatMonto(concepto.autorizado)}</td>
          <td>${formatMonto(concepto.importe_solicitado)}</td>
          <td>${formatMonto(concepto.importe)}</td>
          <td>
            <button
              onClick={() => {
                handleComments(concepto);
              }}
              className="btn btn-sm btn-outline-primary ms-2"
            >
              <i className="fa fa-comment" />
            </button>
          </td>
        </tr>
      ));
    }
  };

  return (
    <div className="table-responsive px-0">
      <table className="table border">
        <thead>
          <tr className="bold text-center small bg-light">
            <td style={{ width: 100 }}>CLAVE</td>
            <td>UNIDAD</td>
            <td style={{ width: 100 }}>CANTIDAD CONTRATADA</td>
            <td style={{ width: 100 }}>CANTIDAD ACUMULADA ANTERIOR</td>
            <td className="text-start">
              {getValue(estimacion, "tipo") === "extra"
                ? "PRECIO CONTRATISTA"
                : "PRECIO"}
            </td>
            {getValue(estimacion, "tipo") === "extra" && (
              <td>PRECIO CONTROL</td>
            )}
            <td>CONTRATISTA</td>
            <td>SUPERVISION</td>
            <td>OBRAS</td>
            <td>IMPORTE SOLICITADO</td>
            <td>IMPORTE AUTORIZADO</td>
            <td>ACCIONES</td>
          </tr>
        </thead>
        <tbody>{renderConceptos()}</tbody>
      </table>
    </div>
  );
};

export default ConceptosEstimacion;
