import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { parse } from "query-string";
import useProyecto from "../hooks/useProyecto";
import useDebounce from "../hooks/useDebounce";
import { ModalContext } from "../context/ModalContext";
import { EntradasContext } from "../context/EntradasContext";
import { ReportesContext } from "../context/ReportesContext";
import ReporteForm from "../components/reportes/ReporteForm";
import { ProyectosContext } from "../context/ProyectosContext";
import AdjuntoEntrada from "../components/bitacora/AdjuntoEntrada";

const ReportPreview = () => {
  const [startDate, setStartDate] = useState(
    moment().startOf("isoWeek").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("week").format("YYYY-MM-DD")
  );
  const [fetching, setFetching] = useState(false);

  const { start_date, end_date } = parse(window.location.search);
  const { proyecto, idProyecto } = useProyecto();

  const { resumen, entradas, getEntradas, getEntradasResumen } =
    useContext(EntradasContext);

  const { getProgresoObra } = useContext(ProyectosContext);
  const { modalComponent } = useContext(ModalContext);
  const { setReporte } = useContext(ReportesContext);

  const weeksArray =
    new Array(parseInt(proyecto?.semanasTotales)).fill(1) || [];

  const handleSave = () => {
    setReporte({
      resumen,
      idProyecto,
      fecha_fin: endDate,
      fecha_inicio: startDate,
    });
    modalComponent("Guardar Reporte", <ReporteForm />);
  };

  const fetchData = () => {
    if (startDate !== null && endDate !== null) {
      setFetching(true);
      getProgresoObra(idProyecto, {
        start_date: startDate,
        end_date: endDate,
      });
      const params = {
        end_date: endDate,
        start_date: startDate,
      };
      getEntradas(idProyecto, null, null, params);
      getEntradasResumen(idProyecto, null, null, params);
    }
  };

  useEffect(fetchData, []);

  useDebounce(fetchData, 2000, [startDate, endDate]);

  useEffect(() => {
    if (resumen && resumen !== null) {
      setFetching(false);
    }
  }, [resumen]);

  useEffect(() => {
    if (
      start_date &&
      start_date !== "" &&
      start_date !== null &&
      start_date !== startDate
    ) {
      setStartDate(start_date);
    }
    if (
      end_date &&
      end_date !== "" &&
      end_date !== null &&
      end_date !== endDate
    ) {
      setEndDate(end_date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto, start_date, end_date]);

  const getMonthsArray = () => {
    const months = [];
    if (Array.isArray(weeksArray) && proyecto !== null) {
      const { fecha_inicio } = proyecto;
      let prevIndex = 0;
      let prevMonth = moment(fecha_inicio).month();
      for (const weeks in weeksArray) {
        const weekStart = moment(fecha_inicio).add(weeks, "weeks");
        const is_end = parseInt(weeks) === weeksArray.length - 1;
        if (weekStart.month() !== prevMonth || is_end) {
          const colSpan = weeks - prevIndex + (is_end ? 1 : 0);
          months.push(
            <th key={weekStart.month()} colSpan={colSpan}>
              {is_end
                ? weekStart.format("MMM")
                : weekStart.clone().subtract(1, "month").format("MMM")}
            </th>
          );
          prevIndex = weeks;
          prevMonth = weekStart.month();
        }
      }
    }
    return months;
  };

  const renderResumen = () => {
    if (startDate && endDate !== null) {
      if (resumen === null && fetching) {
        return (
          <span>
            <div className="spinner-border small me-2" /> Arqvisor Bot esta
            generando el resumen del período seleccionado...
          </span>
        );
      }
      if (resumen !== null && !fetching)
        return <p className="resumen">{resumen}</p>;
    }
  };

  const renderImagenes = () => {
    if (Array.isArray(entradas)) {
      return entradas
        .filter(({ Adjunto }) => Adjunto && Adjunto !== null)
        .map(({ Adjunto }) => (
          <AdjuntoEntrada key={Adjunto.idAdjunto} adjunto={Adjunto} />
        ));
    }
  };

  return (
    <div className="container-fluid">
      <div id="report-header" className="row mb-4">
        <div className="col-6">
          <h1 className="h2">Vista Previa de Reporte</h1>
          <button
            className="btn btn-primary"
            disabled={fetching}
            onClick={handleSave}
          >
            <i className="fa fa-save me-1" /> Guardar
          </button>
        </div>
        <div className="col-6">
          <div className="card shadow px-4 py-3">
            <div className="row">
              <div className="col-6">
                <label>Desde</label>
                <input
                  type="date"
                  value={startDate}
                  disabled={fetching}
                  className="form-control"
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="col-6">
                <label>Hasta</label>
                <input
                  type="date"
                  value={endDate}
                  disabled={fetching}
                  className="form-control"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow p-3 px-4">
        <div className="row">
          <table className="table">
            <thead className="border">
              <tr>
                <td className="col-12 col-md-4 px-0 py-0"></td>
                <td className="col-12 col-md-8 px-0 py-0">
                  <table className="table border border-collapse mb-0 align-items-start">
                    <thead>
                      <tr>
                        <th className="col-3 bold">OBRA</th>
                        <th className="col-3">{proyecto?.nombre}</th>

                        <th className="bg-primary text-white">FECHA INICIO</th>
                        <th>
                          {startDate !== null
                            ? moment(startDate).format("DD-MMM")
                            : ""}
                        </th>
                      </tr>
                      <tr>
                        <th style={{ width: 200 }} className="col-3 bold">
                          FECHA REPORTE
                        </th>
                        <th className="col-3">
                          {moment().utc().format("DD MMM")}
                        </th>
                        <th
                          style={{ width: 200 }}
                          className="bg-primary text-white"
                        >
                          FECHA TERMINO
                        </th>

                        <th>
                          {endDate !== null
                            ? moment(endDate).format("DD-MMM")
                            : ""}
                        </th>
                      </tr>
                    </thead>
                  </table>
                </td>
              </tr>
            </thead>
          </table>
        </div>
        <div className="container-fluid px-0">
          <table className="table border">
            <thead className="bg-primary text-white">
              <tr>{getMonthsArray()}</tr>
            </thead>
            <tbody>
              <tr>
                {weeksArray.map((one, index) => (
                  <td
                    key={index}
                    style={{ height: 40 }}
                    className={`${
                      parseInt(proyecto?.semanaActual) > index + 1
                        ? "bg-real"
                        : "bg-light"
                    }`}
                  ></td>
                ))}
              </tr>
              <tr>
                {weeksArray.map((one, index) => (
                  <td key={index} className="small">
                    {moment(proyecto?.fecha_inicio)
                      .utc()
                      .add(index, "weeks")
                      .format("DD-MMM")}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table className="table">
            <tbody>
              <tr className="border">
                <td>{renderImagenes()}</td>
              </tr>
              <tr className="border text-white bold bg-primary">
                <td>RESUMEN</td>
              </tr>
              <tr className="border">
                <td>{renderResumen()}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="table-responsive"></div>
      </div>
    </div>
  );
};

export default ReportPreview;
