import React, { useContext, useEffect } from "react";
import { IonList, IonItem, IonIcon, IonLabel } from "@ionic/react";
import { folder, chevronForwardOutline } from "ionicons/icons";
import { MenuContext } from "../context/MenuContext";
import { redirect } from "react-router-dom";
import { CapacitorContext } from "../context/CapacitorContext";
import Breadcrumb from "../components/common/Breadcrumb";
import FolderIcon from "../components/folder/FolderIcon";
import { FolderContext } from "../context/FolderContext";

const Folders = ({ idProyecto }) => {
  const { platform } = useContext(CapacitorContext);
  const { setSelected } = useContext(MenuContext);
  const { folders, clearFolder, getFoldersProyecto, pushBreadcrumb } =
    useContext(FolderContext);

  useEffect(() => {
    setSelected({ title: "folders" });
    getFoldersProyecto(idProyecto, { root: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePushBreadcrumb = (currentFolder) => {
    if (!currentFolder.root) {
      pushBreadcrumb(currentFolder);
    }
    clearFolder();
  };

  if (platform === "web") {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6">
            <Breadcrumb idProyecto={idProyecto} />
          </div>
        </div>
        <div className="row mt-3 align-items-center">
          {folders?.map((folder) => (
            <FolderIcon
              key={folder.idFolder}
              folder={folder}
              editable={false}
              idProyecto={idProyecto}
              handleBreadcrumb={() => handlePushBreadcrumb(folder)}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid px-0">
      <div className="mx-n-15">
        <IonList inset={true} lines="full" className="shadow-sm">
          <IonItem onClick={() => redirect(`/obra/${idProyecto}/supervision`)}>
            <IonIcon slot="start" icon={folder} color="primary" />
            <IonLabel>Supervisión</IonLabel>
            <IonIcon slot="end" icon={chevronForwardOutline} />
          </IonItem>
          <IonItem onClick={() => redirect(`/obra/${idProyecto}/catalogo`)}>
            <IonIcon slot="start" icon={folder} color="primary" />
            <IonLabel>Catálogo</IonLabel>
            <IonIcon slot="end" icon={chevronForwardOutline} />
          </IonItem>
          <IonItem onClick={() => redirect(`/obra/${idProyecto}/proyecto`)}>
            <IonIcon slot="start" icon={folder} color="primary" />
            <IonLabel>Proyecto</IonLabel>
            <IonIcon slot="end" icon={chevronForwardOutline} />
          </IonItem>
        </IonList>
      </div>
    </div>
  );
};

export default Folders;
