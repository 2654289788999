import React, { useContext } from "react";
import { getValue } from "../../utils";
import { PlantillasAlertaContext } from "../../context/PlantillasAlertaContext";

const PlantillaAlertaForm = ({ handleCancel }) => {
  const {
    plantillaAlerta,
    getPlantillasAlerta,
    savePlantillaAlerta,
    setPropertyPlantillaAlerta,
  } = useContext(PlantillasAlertaContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    savePlantillaAlerta(plantillaAlerta, getPlantillasAlerta);
    handleCancel();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 col-md-6">
          <label>Nombre</label>
          <input
            className="form-control mb-3"
            value={getValue(plantillaAlerta, "name")}
            onChange={(e) => setPropertyPlantillaAlerta("name", e.target.value)}
          />
          <label>Descripción</label>
          <textarea
            rows="2"
            className="form-control mb-3"
            value={getValue(plantillaAlerta, "description")}
            onChange={(e) =>
              setPropertyPlantillaAlerta("description", e.target.value)
            }
          />

          <label>Tipo de Alerta</label>
          <select
            className="form-control mb-3"
            value={getValue(plantillaAlerta, "idTipoAlerta")}
            onChange={(e) =>
              setPropertyPlantillaAlerta("idTipoAlerta", e.target.value)
            }
          >
            <option value="">Selecionar...</option>
            <option value="1">Retraso</option>
            <option value="2">Notificación</option>
            <option value="3">Verificación</option>
          </select>
          <label>Asunto</label>
          <input
            className="form-control mb-3"
            value={getValue(plantillaAlerta, "subject")}
            onChange={(e) =>
              setPropertyPlantillaAlerta("subject", e.target.value)
            }
          />
        </div>
        <div className="col-12 col-md-6">
          <label>Título</label>
          <input
            className="form-control mb-3"
            value={getValue(plantillaAlerta, "title")}
            onChange={(e) =>
              setPropertyPlantillaAlerta("title", e.target.value)
            }
          />
          <label>Subtítulo</label>
          <input
            className="form-control mb-3"
            value={getValue(plantillaAlerta, "subtitle")}
            onChange={(e) =>
              setPropertyPlantillaAlerta("subtitle", e.target.value)
            }
          />
          <label>Cuerpo</label>
          <textarea
            rows="5"
            className="form-control mb-3"
            value={getValue(plantillaAlerta, "body")}
            onChange={(e) => setPropertyPlantillaAlerta("body", e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <label>Brevo Template Id</label>
          <input
            className="form-control mb-3"
            value={getValue(plantillaAlerta, "brevoTemplateId")}
            onChange={(e) =>
              setPropertyPlantillaAlerta("brevoTemplateId", e.target.value)
            }
          />
        </div>
        <div className="col-6">
          <label>Twilio Content Sid</label>
          <input
            className="form-control mb-3"
            value={getValue(plantillaAlerta, "twilioContentSid")}
            onChange={(e) =>
              setPropertyPlantillaAlerta("twilioContentSid", e.target.value)
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn w-100 text-muted"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn w-100 btn-primary">
            <i className="fa fa-save me-2" /> Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default PlantillaAlertaForm;
