
import React, { createContext, useReducer, useContext } from 'react';
import EstimacionEstadosService from '../services/EstimacionEstadosService';
import EstimacionEstadosReducer from '../reducers/EstimacionEstadosReducer';
import {
  ESTADOESTIMACIONS_RECEIVED,
  SET_ESTADOESTIMACION,
  CREATE_ESTADOESTIMACION,
  SET_PROPERTY_ESTADOESTIMACION,
} from "../types/estadosEstimacion";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  estadosEstimacion: null,
  estadoEstimacion: null,
};

export const EstimacionEstadosContext = createContext(initialState);

export const EstimacionEstadosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(EstimacionEstadosReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getEstimacionEstados = () => {
    EstimacionEstadosService.getEstimacionEstados()
      .then((response) => {
        const { estadosEstimacion } = response.data;
        dispatch({ type: ESTADOESTIMACIONS_RECEIVED, payload: estadosEstimacion });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleEstimacionEstado = (idEstimacionEstado) => {
    EstimacionEstadosService.getSingleEstimacionEstado(idEstimacionEstado)
      .then((response) => {
        const { estimacionEstado } = response.data;
        console.log(estimacionEstado);
        dispatch({ type: SET_ESTADOESTIMACION, payload: estimacionEstado });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setEstimacionEstado = (EstimacionEstado) => {
    dispatch({ type: SET_ESTADOESTIMACION, payload: EstimacionEstado });
  };

  const createEstimacionEstado = () => {
    dispatch({ type: CREATE_ESTADOESTIMACION });
  };

  const setPropertyEstimacionEstado = (key, value) => {
    dispatch({ type: SET_PROPERTY_ESTADOESTIMACION, payload: { key, value } });
  };

  const saveEstimacionEstado = (EstimacionEstado, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = EstimacionEstadosService.putEstimacionEstado;
      if(isNaN(parseInt(EstimacionEstado.idEstimacionEstado))) {
        service = EstimacionEstadosService.postEstimacionEstado;
      }
      service(EstimacionEstado).then(() => {
        success("EstimacionEstado saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteEstimacionEstado = (idEstimacionEstado, callback) => {
     dispatch({ type: SHOW_SPINNER });
    EstimacionEstadosService.deleteEstimacionEstado(idEstimacionEstado).then(() => {
      success("EstimacionEstado deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <EstimacionEstadosContext.Provider
    value={{
      ...state,
      setEstimacionEstado,
      getEstimacionEstados,
      saveEstimacionEstado,
      deleteEstimacionEstado,
      createEstimacionEstado,
      getSingleEstimacionEstado,
      setPropertyEstimacionEstado,

    }}
  >
    {children}
  </EstimacionEstadosContext.Provider>
);
};
