import { getArgs } from "../utils";
import api from "./api";

const route = "/folders";

export default {
  getFolder: (idProyecto, idFolder) =>
    api.get(`${route}/${idProyecto}/${idFolder}`),
  getFoldersProyecto: (idProyecto, filters) => api.get(`${route}/${idProyecto}?${getArgs(filters)}`),
  getAdjuntoFolder: (idProyecto, idAdjunto) =>
    api.get(`${route}/${idProyecto}/adjunto/${idAdjunto}`, {
      responseType: "arraybuffer",
    }),
  postFolder: (idProyecto, idPadre, nombre) =>
    api.post(`${route}/${idProyecto}/${idPadre}`, { nombre }),
  putFolder: (idProyecto, idFolder, nombre) =>
    api.put(`${route}/${idProyecto}/${idFolder}`, { nombre }),
  deleteFolder: (idProyecto, idFolder) =>
    api.delete(`${route}/${idProyecto}/${idFolder}`),
};
