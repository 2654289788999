import React from 'react';

const ConfirmDelete = ({ message, handleCancel, handleDelete }) => {
    return (
      <div>
        <p>{message}</p>
        <p>Esta acción NO puede deshacerse</p>
        <div className="row">
          <div className="col-6">
            <button onClick={handleCancel} className="btn w-100 text-muted">
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button onClick={handleDelete} className="btn btn-danger w-100">
              Eliminar
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  export default ConfirmDelete;