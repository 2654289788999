import api from "./api";

const route = "/reportes";

export default {
  getReportes: (idProyecto) => api.get(`${route}/${idProyecto}`),
  getSingleReporte: (idProyecto, idReporte) => api.get(`${route}/${idProyecto}/${idReporte}`),
  postReporte: (Reporte) => api.post(route, { ...Reporte }),
  generarReporte: (idProyecto, start_date, end_date) =>
    api.post(
      `${route}/${idProyecto}/generate/pdf?start_date=${start_date}&end_date=${end_date}`
    ),
  putReporte: (Reporte) => api.put(route, { ...Reporte }),
  deleteReporte: (idReporte) => api.delete(`${route}/${idReporte}`),
};
