import React, { useContext, useEffect } from "react";
import { AppConfigContext } from "../context/AppConfigContext";
import QRCode from "react-qr-code";
import { MenuContext } from "../context/MenuContext";

const WhatsApp = () => {
  const { whatsapp_qrcode, whatsapp_status } = useContext(AppConfigContext);
  const { selectTabs, setSelected } = useContext(MenuContext);

  useEffect(() => {
    selectTabs();
    setSelected({ title: "WhatsApp" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderQrCode = () => {
    if (whatsapp_status !== "connected" && whatsapp_qrcode && whatsapp_qrcode !== null) {
      return <QRCode value={whatsapp_qrcode} />;
    }
  };

  const renderStatus = () => {
    return (
      <span
        className={`badge d-inline-block mb-3 text-capitalize badge-pill ${
          whatsapp_status === "connected" ? "bg-success" : "bg-danger"
        }`}
      >
        {whatsapp_status}
      </span>
    );
  };

  return (
    <div className="container-fluid">
      <div className="card p-3 shadow">
        <h1 className="h3 pb-2 mb-3 border-bottom">
          Conectar Arqvisor WhatsApp Bot
        </h1>
        {renderStatus()}
        {renderQrCode()}
      </div>
    </div>
  );
};

export default WhatsApp;
