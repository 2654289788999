import api from "./api";

const route = "/plantillasAlerta";

export default {
  getPlantillasAlerta: () => api.get(route),
  getSinglePlantillaAlerta: (notificationTemplate_id) =>
    api.get(`${route}/${notificationTemplate_id}`),
  postPlantillaAlerta: (plantillaAlerta) =>
    api.post(route, { ...plantillaAlerta }),
  putPlantillaAlerta: (plantillaAlerta) =>
    api.put(route, { ...plantillaAlerta }),
  deletePlantillaAlerta: (PlantillaAlerta) =>
    api.delete(`${route}/${PlantillaAlerta}`),
};
