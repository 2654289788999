import React, { useEffect } from "react";
import ReactSelect from "react-select";

const defaultModifier = () => {
  console.error("No modifier passed to DynamicMultipleSearchSelect");
};

const DynamicMultipleSearchSelect = ({
  valueKey,
  labelKey,
  getItems,
  value = [],
  items = [],
  modifier = defaultModifier,
}) => {
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = (query) => {
    if(typeof getItems === "function")
    getItems({ query });
  };

  const getOptions = () => {
    if (!Array.isArray(items)) return [];
    return items.map((item) => ({
      value: item[valueKey],
      label: item[labelKey],
    }));
  };

  const getValue = (options) => {
    if (Array.isArray(value)) {
      return options.filter((option) => value.includes(option.value));
    }
  };

  const renderSelect = () => {
    const options = getOptions();
    const value = getValue(options);
    return (
      <ReactSelect
        isMulti
        isSearchable
        value={value}
        className="px-0"
        options={options}
        onInputChange={fetchData}
        onChange={(value) => modifier(value)}
      />
    );
  };

  return <div>{renderSelect()}</div>;
};

export default DynamicMultipleSearchSelect;
