
import {
  SET_ESTIMACIONESTADOALERTA,
  CREATE_ESTIMACIONESTADOALERTA,
  ESTIMACIONESTADOALERTAS_RECEIVED,
  SET_PROPERTY_ESTIMACIONESTADOALERTA,
} from "../types/estimacionEstadoAlertas";

const schema = {

}

const EstimacionEstadoAlertasReducer = (state, { type, payload }) => {
  switch (type) {
    case ESTIMACIONESTADOALERTAS_RECEIVED:
      return { ...state, estimacionEstadoAlertas: payload };
    case SET_ESTIMACIONESTADOALERTA:
      return { ...state, estimacionEstadoAlerta: payload };
    case CREATE_ESTIMACIONESTADOALERTA:
      return { ...state, estimacionEstadoAlerta: schema };
    case SET_PROPERTY_ESTIMACIONESTADOALERTA: {
      const { key, value } = payload;
      const estimacionEstadoAlerta = { ...state.estimacionEstadoAlerta };
      estimacionEstadoAlerta[key] = value;
      return { ...state, estimacionEstadoAlerta };
    }
    default:
      return { ...state};
  }
};

export default EstimacionEstadoAlertasReducer;
