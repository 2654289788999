
import { getArgs } from '../utils';
import api from './api';

const route = '/estimacionEstadoAlertas';

export default {
  getEstimacionEstadoAlertas: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getSingleEstimacionEstadoAlerta: (idEstimacionEstadoAlerta) => api.get(`${route}/${idEstimacionEstadoAlerta}`),
  postEstimacionEstadoAlerta: (EstimacionEstadoAlerta) => api.post(route, { ...EstimacionEstadoAlerta}),
  putEstimacionEstadoAlerta: (EstimacionEstadoAlerta) => api.put(route, { ...EstimacionEstadoAlerta}),
  deleteEstimacionEstadoAlerta: (idEstimacionEstadoAlerta) => api.delete(`${route}/${idEstimacionEstadoAlerta}`),
};
