import React, { useContext, useEffect } from "react";
import moment from "moment";
import { Gantt } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { getValue } from "../../utils";
import { AppConfigContext } from "../../context/AppConfigContext";
import { CapacitorContext } from "../../context/CapacitorContext";
import "./charts.css";

const GanttChart = ({ partidas }) => {
  const appconfig = useContext(AppConfigContext);
  const { platform } = useContext(CapacitorContext);

  const tasks = partidas?.map((partida) => {
    const progreso =
      getValue(appconfig, "progress") === "money"
        ? partida.progreso
        : partida.real * 100;
    return {
      start: moment(partida.fecha_inicio).toDate(),
      end: moment(partida.fecha_fin).toDate(),
      name: `${partida.nombre} (${progreso}%)`,
      id: partida.idPartida,
      type: "task",
      dependencies: partida.predecesoras.map(({ idPartida }) => idPartida),
      progress: progreso,
      isDisabled: true,
    };
  });

  useEffect(() => {
    changeWeeksLang();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  const changeWeeksLang = () => {
    if (platform !== "web") {
      const weekElements = document.querySelectorAll("._9w8d5");

      if (weekElements.length > 0) {
        weekElements.forEach((element) => {
          const currentText = element.innerHTML;
          element.innerHTML = currentText.replace(/W/g, "S");
        });
      }
    }
  };

  return (
    <div>
      {Array.isArray(partidas) ? (
        <Gantt
          locale="es"
          tasks={tasks}
          preStepsCount={0}
          viewMode={"Week"}
          columnWidth={100}
          barProgressColor={getValue(appconfig, "primary")}
        />
      ) : (
        <div className="spinner-border"></div>
      )}
    </div>
  );
};

export default GanttChart;
