import React, { useContext, useEffect, useState } from "react";
import { PlantillasAlertaContext } from "../context/PlantillasAlertaContext";
import PlantillaAlertaCard from "../components/plantillasAlerta/PlantillaAlertaCard";
import PlantillaAlertaForm from "../components/plantillasAlerta/PlantillaAlertaForm";
import { ModalContext } from "../context/ModalContext";
import { MenuContext } from "../context/MenuContext";
import ConfirmDelete from "../components/global/ConfirmDelete";

const PlantillasAlerta = () => {
  const [query, setQuery] = useState("");
  const { setSelected, selectTabs } = useContext(MenuContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const {
    plantillasAlerta,
    setPlantillaAlerta,
    getPlantillasAlerta,
    createPlantillaAlerta,
    deletePlantillaAlerta,
  } = useContext(PlantillasAlertaContext);

  useEffect(() => {
    selectTabs();
    getPlantillasAlerta();
    setSelected({ title: "Plantillas" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    clearModal();
    setPlantillaAlerta(null);
  };

  const handleDelete = (plantillasAlerta) => {
    modalComponent(
      "Eliminar Plantilla",
      <ConfirmDelete
        message={`¿Estás seguro que deseas eliminar la plantilla ${plantillasAlerta.name}?`}
        handleCancel={handleCancel}
        handleDelete={() =>
          deletePlantillaAlerta(plantillasAlerta.idPlantillaAlerta)
        }
      />
    );
  };

  const handleEdit = (plantillaAlerta) => {
    setPlantillaAlerta(plantillaAlerta);
    modalComponent(
      "Agregar Plantilla",
      <PlantillaAlertaForm handleCancel={handleCancel} />,
      {
        size: "lg",
      }
    );
  };

  const handleCreate = () => {
    createPlantillaAlerta();
    modalComponent(
      "Agregar Plantilla",
      <PlantillaAlertaForm handleCancel={handleCancel} />,
      {
        size: "lg",
      }
    );
  };

  const renderPlantillas = () => {
    if (Array.isArray(plantillasAlerta)) {
      if (plantillasAlerta.length === 0) {
        return <p>No hay plantillas registradas.</p>;
      }
      let plantillasRender = [...plantillasAlerta];
      if (query !== "") {
        plantillasRender = plantillasRender.filter(({ name }) =>
          String(name).toLowerCase().includes(String(query).toLowerCase())
        );
      }
      return (
        <div className="row">
          {plantillasRender.map((template) => (
            <PlantillaAlertaCard
              key={template.idPlantillaAlerta}
              handleDelete={handleDelete}
              plantillaAlerta={template}
              handleEdit={handleEdit}
            />
          ))}
        </div>
      );
    }
  };

  return (
    <div
      className="container-fluid"
      style={{ overflowY: "auto", height: "100%" }}
    >
      <div className="row align-items-center mb-3">
        <div className="col-12 col-md-8">
          <h1>Plantillas</h1>
        </div>
        <div className="col-12 col-md-4 text-end">
          <button onClick={handleCreate} className="btn btn-primary">
            <i className="fa fa-plus" />
          </button>
        </div>
      </div>
      <input
        type="text"
        value={query}
        onChange={e => setQuery(e.target.value)}
        className="form-control border bg-white mb-3"
        placeholder="Buscar por palabra clave"
      />
      {renderPlantillas()}
    </div>
  );
};

export default PlantillasAlerta;
