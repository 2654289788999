//Bitacora
export const PREPEND_ENTRADAS = "PREPEND_ENTRADAS";
export const SET_MAX_ENTRADAS = "SET_MAX_ENTRADAS";
export const APPEND_ENTRADA = "APPEND_ENTRADA";
export const APPEND_ADJUNTO = "APPEND_ADJUNTO";
export const CANCEL_ADJUNTO = "CANCEL_ADJUNTO";
export const CLEAR_ADJUNTOS = "CLEAR_ADJUNTOS";
export const CLEAR_BITACORA = "CLEAR_BITACORA";
export const RESET_ENTRADAS = "RESET_ENTRADAS";
export const SET_RESUMEN = "SET_RESUMEN";
export const SET_PARAMS = "SET_PARAMS";