import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as PDFIcon } from "../../assets/pdf.svg";
import { AdjuntosContext } from "../../context/AdjuntosContext";
import { Link } from "react-router-dom";
import useProyecto from "../../hooks/useProyecto";

const AdjuntoEntrada = ({ adjunto }) => {
  const [src, setSrc] = useState("");

  const { getAdjuntoProyecto, setSingleAdjunto } = useContext(AdjuntosContext);
  const { idProyecto } = useProyecto();

  useEffect(() => {
    if (["png", "jpeg", "jpg", "gif", "webp"].includes(adjunto.tipo)) {
      getAdjuntoProyecto(idProyecto, adjunto.idAdjunto, handleAdjuntoCallback);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleAdjuntoCallback(current) {
    setSrc(current);
  }

  if (["pdf", "PDF"].includes(adjunto.tipo)) {
    return (
      <div
        onClick={() => setSingleAdjunto(adjunto)}
        className="row py-2 mb-2 mx-0 align-items-center bg-light br-10 border"
      >
        <div className="col-3">
          <PDFIcon />
        </div>
        <div className="col-9">
          <p className="bold text-dark mb-1">
            {adjunto.nombre}.{adjunto.tipo}
          </p>
          <Link
            to={`/obra/${idProyecto}/supervision/${adjunto.AdjuntoFolders[0]?.idFolder}`}
            className="small btn btn-sm px-0 d-inline-block text-capitalize text-dark text-decoration-underline"
            onClick={(e) => e.stopPropagation()}
          >
            <i className="fa fa-eye me-2" />
            {adjunto.AdjuntoFolders[0]?.Folder?.nombre || "Folder"}
          </Link>
        </div>
      </div>
    );
  }
  if (src !== "") {
    return (
      <img
        src={src}
        alt="adjunto"
        onClick={() => setSingleAdjunto(adjunto)}
        className="adjunto-mensaje d-inline-block mb-2 me-2"
      />
    );
  }

  return (
    <div className="row mx-0 mb-2 align-items-center">
      <div className="spinner-border"></div>
      <span className="ml-3">Cargando adjunto...</span>
    </div>
  );
};

export default AdjuntoEntrada;
