import React, { useContext, useEffect } from "react";
import { ReportesContext } from "../context/ReportesContext";
import useProyecto from "../hooks/useProyecto";
import moment from "moment";
import { Link } from "react-router-dom";

const Reportes = () => {
  const { reportes, getReportes } = useContext(ReportesContext);
  const { idProyecto } = useProyecto();

  useEffect(() => {
    getReportes(idProyecto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderReportes = () => {
    if (Array.isArray(reportes)) {
      return (
        <div className="table-responsive">
          <table className="table">
            <thead className="bg-light border bold">
              <tr>
                <th>#ID</th>
                <th>Nombre</th>
                <th>Fecha Inicio</th>
                <th>Fecha Fin</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {reportes.map((reporte) => (
                <tr key={reporte.idReporte} className="border-bottom">
                  <td>{reporte.idReporte}</td>
                  <td>{reporte.nombre}</td>
                  <td>{moment(reporte.fecha_inicio).utc().format("DD MMM YYYY")}</td>
                  <td>{moment(reporte.fecha_fin).utc().format("DD MMM YYYY")}</td>
                  <td>
                    <Link
                      to={`/obra/${idProyecto}/reportes/${reporte.idReporte}`}
                      className="btn btn-sm btn-outline-primary me-2"
                    >
                      <i className="fa fa-eye" />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return <div className="spinner-border" />;
  };

  return (
    <div className="container-fluid">
      <div className="row align-items-center mb-3">
        <div className="col-10">
        <h1 className="h2 mb-0">Reportes</h1>
        </div>
        <div className="col-2 text-end">
          <Link to="./generador" className="btn btn-primary">
            <i className="fa fa-plus" />
          </Link>
        </div>
      </div>
      
      <div className="card shadow px-4 py-3">{renderReportes()}</div>
    </div>
  );
};

export default Reportes;
