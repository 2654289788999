
import {
  SET_NOTIFICATIONTEMPLATE,
  CREATE_NOTIFICATIONTEMPLATE,
  NOTIFICATIONTEMPLATES_RECEIVED,
  SET_PROPERTY_NOTIFICATIONTEMPLATE,
} from "../types/plantillasAlerta";

const schema = {

}

const PlantillasAlertaReducer = (state, { type, payload }) => {
  switch (type) {
    case NOTIFICATIONTEMPLATES_RECEIVED:
      return { ...state, plantillasAlerta: payload };
    case SET_NOTIFICATIONTEMPLATE:
      return { ...state, plantillaAlerta: payload };
    case CREATE_NOTIFICATIONTEMPLATE:
      return { ...state, plantillaAlerta: schema };
    case SET_PROPERTY_NOTIFICATIONTEMPLATE: {
      const { key, value } = payload;
      const plantillaAlerta = { ...state.plantillaAlerta };
      plantillaAlerta[key] = value;
      return { ...state, plantillaAlerta };
    }
    default:
      return { ...state};
  }
};

export default PlantillasAlertaReducer;
