import React, { useContext, useEffect } from "react";
import { EstimacionEstadoAlertasContext } from "../../context/EstimacionEstadoAlertasContext";
import { EstimacionEstadosContext } from "../../context/EstimacionEstadosContext";
import { PlantillasAlertaContext } from "../../context/PlantillasAlertaContext";
import { PermisosContext } from "../../context/PermisosContext";
import { getValue } from "../../utils";
import ReactSwitch from "react-switch";

const EstimacionEstadoAlertaForm = ({ handleCancel, handleCallback }) => {
  const { permisos, getPermisos } = useContext(PermisosContext);
  const {
    estimacionEstadoAlerta,
    saveEstimacionEstadoAlerta,
    setPropertyEstimacionEstadoAlerta,
  } = useContext(EstimacionEstadoAlertasContext);
  const { estadosEstimacion, estimacionEstado, getEstimacionEstados } =
    useContext(EstimacionEstadosContext);
  const { plantillasAlerta, getPlantillasAlerta } = useContext(
    PlantillasAlertaContext
  );

  useEffect(() => {
    getEstimacionEstados();
    getPlantillasAlerta();
    getPermisos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const estimacionEstadosRender = estadosEstimacion?.filter(({ tipo }) => tipo === estimacionEstado?.tipo);

  const handleSubmit = () => {
    saveEstimacionEstadoAlerta(estimacionEstadoAlerta, handleCallback);
  };

  const renderPrevOptions = () => {
    if (Array.isArray(estimacionEstadosRender)) {
      return estimacionEstadosRender.map((estado) => (
        <option
          key={estado.idEstimacionEstado}
          value={estado.idEstimacionEstado}
        >
          {estado.nombre}
        </option>
      ));
    }
  };

  const renderNextOptions = () => {
    if (Array.isArray(estimacionEstadosRender)) {
      return estimacionEstadosRender.map((estado) => (
        <option
          key={estado.idEstimacionEstado}
          value={estado.idEstimacionEstado}
        >
          {estado.nombre}
        </option>
      ));
    }
  };

  const renderPlantillas = () => {
    if (Array.isArray(plantillasAlerta)) {
      return plantillasAlerta.map((plantilla) => (
        <option
          key={plantilla.idPlantillaAlerta}
          value={plantilla.idPlantillaAlerta}
        >
          {plantilla.name}
        </option>
      ));
    }
  };

  const renderPermisos = () => {
    if (Array.isArray(permisos)) {
      return permisos.map((permiso) => (
        <option key={permiso.idPermiso} value={permiso.idPermiso}>
          {permiso.nombre}
        </option>
      ));
    }
  };

  return (
    <div>
      <div className="row mb-3">
        <div className="col-12 col-md-6">
          <label>Estado Anterior</label>
          <select
            className="form-control mb-3"
            value={getValue(estimacionEstadoAlerta, "idEstadoAnterior")}
            onChange={(e) =>
              setPropertyEstimacionEstadoAlerta(
                "idEstadoAnterior",
                e.target.value
              )
            }
          >
            <option value="">Selecionar...</option>
            {renderPrevOptions()}
          </select>
        </div>
        <div className="col-12 col-md-6">
          <label>Estado Actual</label>
          <select
            className="form-control mb-3"
            value={getValue(estimacionEstadoAlerta, "idEstadoActual")}
            disabled={
              getValue(estimacionEstadoAlerta, "idEstadoAnterior") === ""
            }
            onChange={(e) =>
              setPropertyEstimacionEstadoAlerta(
                "idEstadoActual",
                e.target.value
              )
            }
          >
            <option value="">Selecionar...</option>
            {renderNextOptions()}
          </select>
        </div>
      </div>
      <div className="row mb-3 align-items-center">
        <div className="col-12 col-md-6">
          <label>Permiso</label>
          <select
            className="form-control"
            value={getValue(estimacionEstadoAlerta, "idPermiso")}
            onChange={(e) =>
              setPropertyEstimacionEstadoAlerta("idPermiso", e.target.value)
            }
          >
            <option value="">Selecionar...</option>
            {renderPermisos()}
          </select>
        </div>
        <div className="col-12 col-md-6">
          <label className="d-block">¿Enviar al mismo usuario?</label>
          <ReactSwitch
            checked={getValue(estimacionEstadoAlerta, "self", "boolean")}
            onChange={(checked) =>
              setPropertyEstimacionEstadoAlerta("self", checked)
            }
          />
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-12 col-md-6">
          <label>Tipo</label>
          <select
            className="form-control mb-3"
            value={getValue(estimacionEstadoAlerta, "tipo")}
            onChange={(e) =>
              setPropertyEstimacionEstadoAlerta("tipo", e.target.value)
            }
          >
            <option value="">Selecionar...</option>
            <option value="verification">Verificación</option>
            <option value="notification">Notificación</option>
          </select>
        </div>
        <div className="col-12 col-md-6">
          <label>Plantilla</label>
          <select
            className="form-control mb-3"
            value={getValue(estimacionEstadoAlerta, "idPlantillaAlerta")}
            onChange={(e) =>
              setPropertyEstimacionEstadoAlerta(
                "idPlantillaAlerta",
                e.target.value
              )
            }
          >
            <option value="">Selecionar...</option>
            {renderPlantillas()}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <button onClick={handleCancel} className="btn w-100 text-muted">
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button onClick={handleSubmit} className="btn w-100 btn-primary">
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default EstimacionEstadoAlertaForm;
