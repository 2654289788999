
import api from './api';

const route = '/estimacionEstados';

export default {
  getEstimacionEstados: () => api.get(route),
  getSingleEstimacionEstado: (idEstimacionEstado) => api.get(`${route}/${idEstimacionEstado}`),
  postEstimacionEstado: (EstimacionEstado) => api.post(route, { ...EstimacionEstado}),
  putEstimacionEstado: (EstimacionEstado) => api.put(route, { ...EstimacionEstado}),
  deleteEstimacionEstado: (EstimacionEstado) => api.delete(`${route}/${EstimacionEstado}`),
};
