import React, { useContext, useEffect, useState } from "react";
import { EstimacionEstadosContext } from "../context/EstimacionEstadosContext";
import PillTabs from "../components/common/PillTabs";
import { useNavigate } from "react-router-dom";
import { MenuContext } from "../context/MenuContext";

const EstimacionEstados = () => {
  const [view, setView] = useState("normal");
  const navigate = useNavigate();

  const { setSelected, selectTabs } = useContext(MenuContext);
  const { estadosEstimacion, getEstimacionEstados } = useContext(
    EstimacionEstadosContext
  );

  useEffect(() => {
    selectTabs();
    setSelected({ name: "Estados" });
    getEstimacionEstados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid">
      <h1 className="h3">Estados de Estimación</h1>
      <div className="card mb-3 p-3 px-4 px-md-3">
        <PillTabs
          value={view}
          modifier={setView}
          tabs={[
            {
              label: "Normales",
              value: "normal",
            },
            {
              label: "Extras",
              value: "extra",
            },
          ]}
        />
      </div>

      <div className="card px-3">
        {estadosEstimacion
          ?.filter(({ tipo }) => tipo === view)
          .sort((a, b) => (a.order < b.order ? -1 : 1))
          .map((estado) => {
            const alertas =
              estado.EstadoAnterior.length + estado.EstadoActual.length;
            return (
              <div key={estado.idEstimacionEstado} className="row py-3 border-bottom align-items-center">
                <div className="col-6 col-md-8 col-lg-10">
                  <h4 className="h6 mb-0 fw-normal text-large text-capitalize">{estado.orden}. {estado.nombre}</h4>
                </div>
                <div className="col-6 col-md-4 col-lg-2 text-end">
                  <button
                    className="btn btn-outline-primary me-2"
                    onClick={() =>
                      navigate(`/estados/${estado.idEstimacionEstado}/alertas`)
                    }
                  >
                    <i className="fa fa-edit" />
                  </button>
                  <button
                    className="btn btn-outline-primary"
                    onClick={() =>
                      navigate(`/estados/${estado.idEstimacionEstado}/alertas`)
                    }
                  >
                    <i className="fa fa-bell" />
                    {alertas > 0 && <span className="ms-2">{alertas}</span>}
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default EstimacionEstados;
