import React, { useContext, useState } from "react";
import { getFolderIconSrc } from "../../utils/folder";
import { ModalContext } from "../../context/ModalContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import { FolderContext } from "../../context/FolderContext";
import FolderForm from "./FolderForm";
import { AppConfigContext } from "../../context/AppConfigContext";
import { useLocation, useNavigate } from "react-router-dom";

const FolderIcon = ({ folder, handleBreadcrumb }) => {
  const [showOptions, setShowOptions] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const { primary } = useContext(AppConfigContext);

  const { confirm, modalComponent } = useContext(ModalContext);

  const { getFolder, updateFolder, deleteFolder } = useContext(FolderContext);

  const { proyecto } = useContext(ProyectosContext);

  const fetchFolder = () => {
    if (proyecto !== null) {
      const { idProyecto } = proyecto;
      handleBreadcrumb();
      if(folder.nombre === "supervision") {
        return navigate(`/obra/${idProyecto}/supervision`);
      }
      if(folder.nombre === "catalogo") {
        return navigate(`/obra/${idProyecto}/catalogo`);
      }
      if(folder.nombre === "proyecto") {
        return navigate(`/obra/${idProyecto}/proyecto`);
      } else if(!["supervision", "catalogo", "proyecto"].includes(location.pathname)) {
        return navigate(`/obra/${idProyecto}/supervision/${folder.idFolder}`);
      }
      getFolder(proyecto.idProyecto, folder.idFolder);
    }
  };

  const handleRenameFolder = () => {
    setShowOptions(!showOptions);
    modalComponent(
      "Renombrar Carpeta",
      <FolderForm
        handleSubmit={handleUpdateFolder}
        idFolder={folder.idFolder}
        initialValue={folder.nombre}
      />,
      fetchFolder,
      handleUpdateFolder
    );
  };

  const handleUpdateFolder = () => {
    updateFolder(proyecto.idProyecto, folder, fetchFolder);
  };

  const handleDeleteFolder = () => {
    setShowOptions(false);
    confirm(
      `¿Eliminar Carpeta "${folder.nombre}"? Esto eliminará todas las subcarpetas y archivos que contiene. Esta acción NO puede deshacerse.`,
      () => deleteFolder(proyecto.idProyecto, folder.idFolder, fetchFolder)
    );
  };

  const renderOptions = () => {
    if (showOptions) {
      return (
        <div className="card folder-options shadow p-3">
          <button
            className="btn text-secondary text-start"
            onClick={(e) => {
              e.stopPropagation();
              handleRenameFolder();
            }}
          >
            Renombrar
          </button>
          <button
            className="btn text-danger text-start"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteFolder();
            }}
          >
            Eliminar
          </button>
        </div>
      );
    }
  };

  const renderOptionsButton = () => {
    if (folder.editable) {
      return (
        <button
          className="btn btn-link folder-btn text-white"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowOptions(!showOptions);
          }}
        >
          <i className="fa fa-ellipsis-v" />
        </button>
      );
    }
  };


  return (
    <div className="col-6 col-lg-4 col-xl-3 mb-3">
      <div
        onClick={fetchFolder}
        className="cardfolder-card text-white position-relative"
        style={{ backgroundColor: "transparent", border: "none" }}
      >
        {renderOptions()}
        {renderOptionsButton()}
        <img
          alt={folder.nombre}
          src={getFolderIconSrc(primary)}
          className="mw-100"
        />
        <div className="folder-title-container px-3">
          <p className="bold text-center text-capitalize w-100 folder-title mb-0">
            {folder.nombre}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FolderIcon;
