
import React, { createContext, useReducer, useContext } from 'react';
import PlantillasAlertaReducer from '../reducers/PlantillasAlertaReducer';
import PlantillasAlertaService from '../services/PlantillasAlertaService';
import {
  NOTIFICATIONTEMPLATES_RECEIVED,
  SET_NOTIFICATIONTEMPLATE,
  CREATE_NOTIFICATIONTEMPLATE,
  SET_PROPERTY_NOTIFICATIONTEMPLATE,
} from "../types/plantillasAlerta";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  plantillasAlerta: null,
  plantillaAlerta: null,
};

export const PlantillasAlertaContext = createContext(initialState);

export const PlantillasAlertaProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PlantillasAlertaReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getPlantillasAlerta = () => {
    PlantillasAlertaService.getPlantillasAlerta()
      .then((response) => {
        const { plantillasAlerta } = response.data;
        dispatch({ type: NOTIFICATIONTEMPLATES_RECEIVED, payload: plantillasAlerta });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSinglePlantillaAlerta = (notificationTemplate_id) => {
    PlantillasAlertaService.getSinglePlantillaAlerta(notificationTemplate_id)
      .then((response) => {
        const { plantillaAlerta } = response.data;
        dispatch({ type: SET_NOTIFICATIONTEMPLATE, payload: plantillaAlerta });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setPlantillaAlerta = (plantillaAlerta) => {
    dispatch({ type: SET_NOTIFICATIONTEMPLATE, payload: plantillaAlerta });
  };

  const createPlantillaAlerta = () => {
    dispatch({ type: CREATE_NOTIFICATIONTEMPLATE });
  };

  const setPropertyPlantillaAlerta = (key, value) => {
    dispatch({ type: SET_PROPERTY_NOTIFICATIONTEMPLATE, payload: { key, value } });
  };

  const savePlantillaAlerta = (plantillaAlerta, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = PlantillasAlertaService.putPlantillaAlerta;
      if(isNaN(parseInt(plantillaAlerta.notificationTemplate_id))) {
        service = PlantillasAlertaService.postPlantillaAlerta;
      }
      service(plantillaAlerta).then(() => {
        success("PlantillaAlerta saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deletePlantillaAlerta = (notificationTemplate_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    PlantillasAlertaService.deletePlantillaAlerta(notificationTemplate_id).then(() => {
      success("PlantillaAlerta deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <PlantillasAlertaContext.Provider
    value={{
      ...state,
      setPlantillaAlerta,
      getPlantillasAlerta,
      savePlantillaAlerta,
      deletePlantillaAlerta,
      createPlantillaAlerta,
      getSinglePlantillaAlerta,
      setPropertyPlantillaAlerta,

    }}
  >
    {children}
  </PlantillasAlertaContext.Provider>
);
};
