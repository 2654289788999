import React, { useContext, useEffect } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import useProyecto from "../hooks/useProyecto";
import { ReportesContext } from "../context/ReportesContext";
import AdjuntoEntrada from "../components/bitacora/AdjuntoEntrada";

const SingleReporte = () => {
  const { idProyecto, proyecto } = useProyecto();
  const { idReporte } = useParams();

  const { reporte, getSingleReporte } = useContext(ReportesContext);

  const weeksArray =
    new Array(parseInt(proyecto?.semanasTotales)).fill(1) || [];

  useEffect(() => {
    getSingleReporte(idProyecto, idReporte);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto, idReporte]);

  const getMonthsArray = () => {
    const months = [];
    if (Array.isArray(weeksArray) && proyecto !== null) {
      const { fecha_inicio } = proyecto;
      let prevIndex = 0;
      let prevMonth = moment(fecha_inicio).month();
      for (const weeks in weeksArray) {
        const weekStart = moment(fecha_inicio).add(weeks, "weeks");
        const is_end = parseInt(weeks) === weeksArray.length - 1;
        if (weekStart.month() !== prevMonth || is_end) {
          const colSpan = weeks - prevIndex + (is_end ? 1 : 0);
          months.push(
            <th key={weekStart.month()} colSpan={colSpan}>
              {is_end
                ? weekStart.format("MMM")
                : weekStart.clone().subtract(1, "month").format("MMM")}
            </th>
          );
          prevIndex = weeks;
          prevMonth = weekStart.month();
        }
      }
    }
    return months;
  };

  const renderImagenes = () => {
    if (Array.isArray(reporte?.entradas)) {
      return reporte.entradas
        .filter(({ Adjunto }) => Adjunto && Adjunto !== null)
        .map(({ Adjunto }) => (
          <AdjuntoEntrada key={Adjunto.idAdjunto} adjunto={Adjunto} />
        ));
    }
  };

  return (
    <div className="container-fluid">
      <div id="report-header" className="row mb-4 align-items-center">
        <div className="col-10">
          <h1 className="h2">Reporte</h1>
        </div>
        <div className="col-2 text-end">
          <button className="btn btn-primary" onClick={window.print}>
            <i className="fa fa-print" />
          </button>
        </div>
      </div>
      <div className="card shadow p-3 px-4">
        <div className="row">
          <table className="table">
            <thead className="border">
              <tr>
                <td className="col-12 col-md-4 px-0 py-0"></td>
                <td className="col-12 col-md-8 px-0 py-0">
                  <table className="table border border-collapse mb-0 align-items-start">
                    <thead>
                      <tr>
                        <th className="col-3 bold">OBRA</th>
                        <th className="col-3">{proyecto?.nombre}</th>

                        <th className="bg-primary text-white">FECHA INICIO</th>
                        <th>{moment(reporte?.fecha_inicio).utc().format("DD-MMM")}</th>
                      </tr>
                      <tr>
                        <th style={{ width: 200 }} className="col-3 bold">
                          FECHA REPORTE
                        </th>
                        <th className="col-3">
                          {moment().utc().format("DD MMM")}
                        </th>
                        <th
                          style={{ width: 200 }}
                          className="bg-primary text-white"
                        >
                          FECHA TERMINO
                        </th>
                        <th>{moment(reporte?.fecha_fin).utc().format("DD-MMM")}</th>
                      </tr>
                    </thead>
                  </table>
                </td>
              </tr>
            </thead>
          </table>
        </div>
        <div className="container-fluid px-0">
          <table className="table border">
            <thead className="bg-primary text-white">
              <tr>{getMonthsArray()}</tr>
            </thead>
            <tbody>
              <tr>
                {weeksArray.map((one, index) => (
                  <td
                    key={index}
                    style={{ height: 40 }}
                    className={`${
                      parseInt(reporte?.semanaActual) > index + 1
                        ? "bg-real"
                        : "bg-light"
                    }`}
                  ></td>
                ))}
              </tr>
              <tr>
                {weeksArray.map((one, index) => (
                  <td key={index} className="small">
                    {moment(proyecto?.fecha_inicio)
                      .utc()
                      .add(index, "weeks")
                      .format("DD-MMM")}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table className="table">
            <tbody>
              <tr className="border">
                <td>{renderImagenes()}</td>
              </tr>
              <tr className="border text-white bold bg-primary">
                <td>RESUMEN</td>
              </tr>
              <tr className="border">
                <td>
                  <p className="resumen">{reporte?.resumen}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="table-responsive"></div>
      </div>
    </div>
  );
};

export default SingleReporte;
