import React, { useContext } from "react";
import { getConceptosKeys, getSumConcepto } from "../../utils/conceptos";
import { getValue, formatMonto } from "../../utils";
import { ConceptosUbicacionesContext } from "../../context/ConceptosUbicacionesContext";
import { AuthContext } from "../../context/AuthContext";

const ConceptoMedicionFormMobile = ({
  concepto,
  idProyecto,
  idContrato,
  handleCancel,
  handleCallback,
  idEstimacionVersion,
}) => {
  const { user } = useContext(AuthContext);
  const { saveConceptoUbicacion, setPropertyConceptosUbicacion } = useContext(
    ConceptosUbicacionesContext
  );

  const handleSave = () => {
    if (!isNaN(parseInt(concepto.idConceptoMedicion))) {
      if (concepto.idUsuario !== user.idUsuario) {
        delete concepto.idConceptoMedicion;
        delete concepto.idUsuario;
      }
    }
    concepto.idEstimacionVersion = idEstimacionVersion;
    saveConceptoUbicacion(idProyecto, idContrato, concepto, handleCallback);
  };

  const renderCampos = () => {
    const keys = getConceptosKeys(concepto);
    return keys.map((key) => (
      <div key={key} className="mb-3 col-6">
        <label className="text-capitalize">{key}</label>
        <input
          type="text"
          className="form-control"
          value={getValue(concepto, key)}
          onChange={(e) =>
            setPropertyConceptosUbicacion(
              concepto.idConceptoUbicacion,
              key,
              e.target.value
            )
          }
        />
      </div>
    ));
  };

  const renderTotal = () => {
    const total = getSumConcepto(concepto);
    return formatMonto(total);
  };

  return (
    <div>
      <div className="row">
        {["eje", "tramo", "tipo", "nivel"].map((key) => (
          <div className="mb-3 col-6" key={key}>
            <label className="text-capitalize">{key}</label>
            <input
              type="text"
              className="form-control"
              value={getValue(concepto, key)}
              onChange={(e) =>
                setPropertyConceptosUbicacion(
                  concepto.idConceptoUbicacion,
                  key,
                  e.target.value
                )
              }
            />
          </div>
        ))}
        {renderCampos()}
      </div>
      <div className="row mb-3 mx-0">
        <div className="col-6 bold border py-2">Total</div>
        <div className="col-6 text-end border py-2">{renderTotal()}</div>
      </div>
      <div className="row mt-3">
        <div className="col-6 px-0">
          <button
            onClick={() => handleCancel(concepto.idConceptoUbicacion)}
            className="btn w-100 text-muted"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6 px-0">
          <button
            onClick={handleSave}
            className="btn w-100 btn-primary"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConceptoMedicionFormMobile;
