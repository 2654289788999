import React, { useContext, useEffect } from "react";
import { ProyectosProvider } from "./context/ProyectosContext";
import { ModalProvider } from "./context/ModalContext";
import { MenuProvider } from "./context/MenuContext";
import { UserProvider } from "./context/AuthContext";
import { ChecklistProvider } from "./context/ChecklistContext";
import { UsuariosProvider } from "./context/UsuariosContext";
import { PermisosProvider } from "./context/PermisosContext";
import { ClientesProvider } from "./context/ClientesContext";
import { AdjuntosProvider } from "./context/AdjuntosContext";
import { FolderProvider } from "./context/FolderContext";
import { EntradasProvider } from "./context/EntradasContext";
import {
  CapacitorContext,
  CapacitorProvider,
} from "./context/CapacitorContext";
import { CronogramaProvider } from "./context/CronogramaContext";
import { PartidasProvider } from "./context/PartidasContext";
import { AlertasProvider } from "./context/AlertasContext";
import { RecorridosProvider } from "./context/RecorridosContext";
import { DestajosProvider } from "./context/DestajosContext";
import { SubdestajosProvider } from "./context/SubdestajosContext";
import { PlantillasProvider } from "./context/PlantillasContext";
import { EditModeProvider } from "./context/EditModeContext";
import { EstimacionesProvider } from "./context/EstimacionesContext";
import { ContratosProvider } from "./context/ContratosContext";
import { ConceptosProvider } from "./context/ConceptosContext";
import { PagosProvider } from "./context/PagosContext";
import { RetrasosProvider } from "./context/RetrasosContext";
import { ProveedoresProvider } from "./context/ProveedoresContext";
import { MontosProvider } from "./context/MontosContext";
import { ProductsProvider } from "./context/ProductsContext";
import { WarehousesProvider } from "./context/WarehousesContext";
import { PartidasContratoProvider } from "./context/PartidasContratoContext";
import { PreciarioProvider } from "./context/PreciarioContext";
import { OrdenesProvider } from "./context/OrdenesContext";
import { IonApp, setupIonicReact } from "@ionic/react";
import { combineComponents } from "./context";
import {
  AppConfigContext,
  AppConfigProvider,
} from "./context/AppConfigContext";
import Main from "./Main";
import "@ionic/react/css/core.css";
import { ItemsProvider } from "./context/ItemsContext";
import { RevisionesProvider } from "./context/RevisionesContext";
import { UserDevicesProvider } from "./context/UserDevicesContext";
import { EstimacionVersionesProvider } from "./context/EstimacionVersionesContext";
import { ConceptosMedicionesProvider } from "./context/ConceptosMedicionesContext";
import { ConceptosUbicacionesProvider } from "./context/ConceptosUbicacionesContext";
import { PlantillasAlertaProvider } from "./context/PlantillasAlertaContext";
import { CambioEstimacionVersionesProvider } from "./context/CambioEstimacionVersionsContext";
import { EstimacionEstadosProvider } from "./context/EstimacionEstadosContext";
import { EstimacionEstadoAlertasProvider } from "./context/EstimacionEstadoAlertasContext";
import { ReportesProvider } from "./context/ReportesContext";

setupIonicReact();

const AppContextProviderWrapper = combineComponents([
  CambioEstimacionVersionesProvider,
  EstimacionEstadoAlertasProvider,
  ConceptosUbicacionesProvider,
  EstimacionVersionesProvider,
  ConceptosMedicionesProvider,
  EstimacionEstadosProvider,
  PartidasContratoProvider,
  PlantillasAlertaProvider,
  EstimacionesProvider,
  UserDevicesProvider,
  SubdestajosProvider,
  ProveedoresProvider,
  PlantillasProvider,
  CronogramaProvider,
  RecorridosProvider,
  WarehousesProvider,
  RevisionesProvider,
  ChecklistProvider,
  ProyectosProvider,
  ContratosProvider,
  ConceptosProvider,
  PreciarioProvider,
  RetrasosProvider,
  DestajosProvider,
  AdjuntosProvider,
  ClientesProvider,
  PermisosProvider,
  UsuariosProvider,
  EntradasProvider,
  PartidasProvider,
  EditModeProvider,
  ReportesProvider,
  ProductsProvider,
  AlertasProvider,
  OrdenesProvider,
  MontosProvider,
  FolderProvider,
  ItemsProvider,
  PagosProvider,
  MenuProvider,
]);

const AppContext = () => {
  const { firebaseConfig, getAppConfig } = useContext(AppConfigContext);
  const { platform } = useContext(CapacitorContext);

  useEffect(() => {
    getAppConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderMain = () => {
    if (platform === "web") {
      return <Main />;
    } else {
      return (
        <IonApp>
          <Main />
        </IonApp>
      );
    }
  };

  const renderApp = () => {
    if (firebaseConfig && firebaseConfig !== null) {
      return (
        <ModalProvider>
          <UserProvider>
            <AppContextProviderWrapper>
              {renderMain()}
            </AppContextProviderWrapper>
          </UserProvider>
        </ModalProvider>
      );
    }
  };

  return <div>{renderApp()}</div>;
};

const App = () => {
  return (
    <AppConfigProvider>
      <CapacitorProvider>
        <AppContext />
      </CapacitorProvider>
    </AppConfigProvider>
  );
};

export default App;
